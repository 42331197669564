

.textOverflow(@fc) {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: @fc;
    -webkit-box-orient: vertical;
}
.translate(@x,@y) {
    -webkit-transform: translate(@x,@y);
    transform: translate(@x,@y);
}