@import "./style/reset.less";
@charset"utf-8";
body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
ul,ol {
    list-style: none;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-menu {
    box-shadow: none;
    background:transparent;
}

.ant-menu-item-group {
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    -webkit-transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
    transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
    background: #fff;
    border: 1px solid #ececec;
}
.news-header {
    overflow: hidden;

    .news-img-left {
        text-align: center;
    }



    .news-icon-detail {
        color: #000;
        font-size: 20px;
    }

    .ant-menu-horizontal {
        line-height: 64px;
        border-bottom: 0px;
        height: 64px;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
        color: #000;
        border-bottom: 2px solid #fff;
    }

    a.active {
        position: relative;
        color: #313784;
        font-weight:bold;
    }

    .active:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
        content: '';
        width: 30px;
        height: 2px;
        left: 50%;
        margin-left: -15px;
        top: 30px;
        background-color: #313784;
    }

    .ant-menu-submenu {
        .active {
            color: #7175b6;
            font-weight: bold;
            position: relative;

            &:before {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: transparent;
                content: '';
                width: 30px;
                height: 2px;
                left: 50%;
                margin-left: -15px;
                top: 30px;
                background-color: #313784;
            }
        }
    }

    .ant-menu-horizontal > .ant-menu-item-selected a {
        color: #000;
    }



    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected .active, .ant-menu-horizontal > .ant-menu-item-selected .active {
        color: #7175b6;
        font-weight: bold;
        position: relative;

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: transparent;
            content: '';
            width: 30px;
            height: 2px;
            left: 50%;
            margin-left: -15px;
            top: 30px;
            background-color: #313784;
        }
    }

    .ant-menu-submenu-popup {
        z-index: 9999999;
    }

    position: relative;
    z-index: 9999;
}
.fixed-head {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 9999;
    border-bottom: 1px solid #ececec;
   
    .ant-menu-horizontal > .ant-menu-item > a::before {
        bottom: 0px
    }
}
.new-group-menu {
    .ant-menu-item-group-title {
        padding: 0px;
    }

    .undefined-item {
        position: relative;
        z-index: 9999;
        cursor:pointer;
        &:hover {
            color: #fdc12c;
        }
    }

    
}
.fixed-head-active {
    background-color: #313784;
    color: #fff;

    .news-icon-detail {
        color: #fff;
        font-size: 20px;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
        color: #fff;
        border-bottom: 2px solid #fff;
    }

    .ant-menu-horizontal > .ant-menu-item a {
        color: #fff;
    }

    .ant-menu {
        background-color: #313784;
        color: #fff;
    }

    a {
        color: #fff;
    }

    .ant-menu-submenu-title:hover {
        color: #fff;
    }

    .ant-menu-horizontal > .ant-menu-item:hover {
        color: #fff;
    }

    .ant-menu-horizontal > .ant-menu-submenu:hover {
        color: #fff;
    }

    .white-text{
        color:#fff;
    }
}
.fixed-head-noactive {
    background-color: #fff;
    color: #000;

   
}
.news-box {
    .intention2 {
        margin-top: 56px;
        margin-bottom: 72px;
    }

    background: #fff;

    .num-yue {
        color: #5d62a4;
    }

    @media (max-width: 768px) {
        .footer-mobile {
            padding-left: 30px;
        }

        .footer-mobile2 {
            padding-left: 66px;
        }
    }

    @media (min-width: 768px) {
        .news-img-banner {
            > img {
                width: 348px !important;
                margin-top: 20px;
            }
        }
    }

    .news-member-bg {
        width: 100%;
        background: url("images/newsBg.jpg") no-repeat;
        height: 380px;
        text-align: center;
        margin-top: 50px;
        background-size: 100% 100%;
    }

    .video-box {
        position: relative;
    }

    .video-play {
        color: #00529f;
        position: absolute;
        bottom: 0px;
        left: 0px;
        font-size: 78px;
    }

    .airport-hg {
        height: 356px;
        overflow-y: auto;
        padding-left: 35px;
    }

    .video-head {
        background-color: #e1e1e1;
        padding-left: 10px;
    }

    .news-footer-box {
        background-color: #313784;
        color: #fff;
        font-size: 16px;
        line-height: 2;
        padding: 20px 0px;
    }

    .news-gary-footer {
        background-color: #525a5c;
        color: #fff;
        padding: 20px;
    }

    .news-black-footer {
        background-color: #000;
    }

    .news-f-b {
        padding: 24px 0px;
        padding-top: 0px;

        a {
            color: #fff;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .news-img-b {
        text-align: center;
    }

    .news-footer {
        width: 82%;
        margin: 0 auto;

        .news-footer-nav {
            padding-left: 15px;
            border-left: 3px solid #fff;
            line-height: 1;
            margin-bottom: 15px;
            cursor: pointer;
        }

        dd {
            padding-left: 18px;
            font-size: 13px;
        }

        .news-footer-item {
            display: inline-block;
            width: 50%;
        }

        .link-th {
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .footer-icon {
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 26px;
            border-radius: 50%;
            border: 1px solid #fff;
            margin-right: 20px;
        }

        .footer-icon-box {
            margin-top: 10px;
        }

        .num-yue {
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        .news-img-left {
            text-align: right;
        }
    }

    .news-img-banner {
        margin-bottom: 20px;
        padding-top: 58px;
    }



    .news-img-banner-box {
        margin-top: 80px;
    }

    .news-become-member {
        background-color: #313784;
        color: #fff;
        /* padding: 16px; */
        display: inline-block;
        font-size: 20px;
        cursor: pointer;
        font-weight: 700;
        line-height: 2;
        height: 60px;
        padding: 4px 32px;
        border: 0px;
    }

    @media (max-width: 768px) {
        .news-member-bg {
            height: 268px;
        }

        .qrcode {
            img {
                width: 90% !important;
            }
        }

        .news-detail {
            padding: 0px 10px;
        }

        .news-img-banner-box {
            margin-top: 50px;
        }

        .news-become-member {
            height: 54px;
            padding: 0px 20px;
        }

        .news-footer {
            width: 100%;
        }

        ul {
            padding-left: 25px;
            padding-right: 10px;
            width: 112px;
        }

        .ant-pagination {
            width: 100%;
            padding-left: 0px;
            margin-bottom: 20px;
        }

        .new-Index .news-like-analysis {
            padding: 0px 32px;
            height: 82px;
            display: inline-block;
            width: 91%;
            margin-bottom: -14px;
            margin-top: -20px;
        }

        .baogao-pl10 {
            padding-left: 20px;
        }

        .intention {
            width: 100%;
        }

        .new-Index .news-two-like {
            left: 0px;
        }

        .baogao-mt10 {
            margin-top: 10px;
        }
    }

    .new-Index-time-box {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .newIndex-devopment {
        padding: 20px;
        border-top: 3px solid #00529f;
        padding-top: 40px;
        padding-bottom: 60px;
    }

    .newIndex-h22 {
        margin-top: 20px;
    }
}

.news-nav-drawer {
    .news-nav {
        font-size: 24px;
        color: #313784;
        line-height: 2;
        padding-bottom: 80px;
    }

    .news-text-m {
        display: inline-block;
        min-width: 175px;
        text-align: left;
        position: relative;
    }





    .new-text-title {
        display: inline-block;
        width: 75px;
        vertical-align: top;
    }
}

.new-Index {
    margin-top: 106px !important;

    .content-box-detail {
        margin-top: 15px;
        color: #000;
        background: #f8f8f8;
        padding: 20px;
    }

    .title-index {
        text-align: center;
    }



    .time-video5 {
        padding-left: 35px;
    }

    .bg-gray3 {
        background: #e1e1e1;
        margin-right: 20px;
    }

    .icon-guanbiw {
        position: absolute;
        top: 0px;
        right: 12px;
        font-size: 26px;
        z-index: 9999;
    }

    .group-box {
        color: #000;
        font-size: 16px;
        padding: 0px 25px;
        margin-top: -32px;
        padding-bottom: 50px;
    }

    .country-blue {
        color: #00529f;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .new-Index-analysis {
        color: #00529f;
        margin: 0 0 64px;
        font-size: 22px;
        font-weight: bold;
        margin-left: 10px;
    }

    .new-Index-Head {
        font-weight: 700;
        font-size: 24px;
        padding: 16px;
        color: #00529f;
        position: relative;
        padding: 0px 0px 0px 16px;
        background-color: #e1e1e1;
    }

    .view-more {
        font-family: Proxima Nova,sans-serif;
        border: 0;
        background-color: #fdb913;
        padding: 16px;
        width: 120px;
        color: #00529f;
        font-weight: 700;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;
    }

    .view-more2 {
        background-color: #fdb913;
        font-weight: 700;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        color: #00529f;
        width: 50px;
        height: 53px;
        line-height: 60px;
        vertical-align: middle;
        margin-left: 10px;
    }

    .new-Index-view-more {
        margin-top: 20px;
    }

    .newIndex-time {
        font-size: 14px;
        color: #aaaaaa;
    }

    .icon-liulang {
        color: #00529f;
        font-size: 30px;
    }

    .icon-liulang-box {
        width: 80px;
        line-height: 55px;
        text-align: center;
        background-color: #fdb913;
    }

    .analysis-head {
        background: #e1e1e1;
        padding-left: 10px;
    }

    .news-left {
        padding-right: 20px;
    }

    .news-right {
        padding-left: 20px;
    }

    .newIndex-h2 {
        color: #000;
        text-decoration: none;
        cursor: pointer;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }
    }

    .small-left {
        padding: 10px;
    }

    .newIndex-small-title {
        .textOverflow(2);
    }

    .new-Index-events {
        font-family: Proxima Nova,sans-serif;
        border: 0;
        background-color: #fdb913;
        padding: 16px;
        font-size: 24px;
        color: #00529f;
        font-weight: 700;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        margin-left: 20px;
    }

    .news-like-this {
        display: inline-block;
        color: #00529f;
        padding: 32px;
        background-color: #f3f3f3;
        font-weight: bold;
    }

    .news-like {
        margin-bottom: 60px;
        position: relative;
    }

    .news-two-like {
        position: absolute;
        top: 50px;
        left: 165px;
    }

    .news-like-analysis {
        width: 70%;
        font-weight: 700;
        padding: 32px;
        background-color: #f3f3f3;
        margin-bottom: 16px;
    }

    .news-detail {
        font-size: 18px;
    }

    .news-avator {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
    }

    .news-like2 {
        position: relative;
    }

    .avatar-box {
        background-color: #f3f3f3;
        padding: 16px 0;
        margin-top: 60px;
    }

    .view-download {
        font-size: 28px;
        font-weight: bold;
    }

    .intention {
        list-style: initial;
    }

    .title-bg {
        padding-left: 30px;
        margin-bottom: 10px;
    }

    .news-index-top3.news-index-top2 {
        padding: 16px;
        background-color: #f3f3f3;
    }

    .img-b {
        box-shadow: 4px 4px 4px #000;
        padding: 0px;
        margin-right: 20px;
    }

    .icon-buy {
        position: absolute;
        right: -15px;
        top: -15px;
        width: 36px;
        height: 36px;
        color: #00529f;
        border-radius: 50%;
        background: #e1e1e1;
        line-height: 36px;
    }

    @media (max-width: 768px) {
        .title-index {
            padding-left: 10px;
            text-align: left;
        }

        .mobile-mt20 {
            margin-top: 20px;
        }

        .news-left {
            padding-right: 0px;
        }

        .news-pd10 {
            padding: 10px;
        }

        .analysis-head {
            padding-left: 0px;
        }

        .news-right {
            padding-left: 0px;
        }

        .airport-hg {
            padding: 10px;
        }

        .time-video5 {
            padding: 10px;
        }

        .news-index-top {
            padding-top: 20px !important;
        }

        .detail-content {
            width: 100%;

            img {
                width: 100%;
            }
        }


        .news-img-logo {
            margin-bottom: 30px;
        }
    }
}

    .mt15 {
        margin-top: 15px;
    }

    .ml10 {
        margin-left: 10px;
    }

    .mb10 {
        margin-bottom: 10px;
    }

    .mb30 {
        margin-bottom: 30px;
    }

    .mb20 {
        margin-bottom: 20px;
    }

    .mt10 {
        margin-top: 10px;
    }

    .text-c {
        text-align: center;
    }

    .gray9 {
        color: #999;
    }

    .mr20 {
        margin-right: 20px;
    }

    .pd10 {
        padding: 0px 10px;
    }

    .none {
        display: none;
    }

    .news-index {


        .become-btn {
            font-family: Proxima Nova,sans-serif;
            border: 0;
            font-size: 24px;
            background-color: #fdb913;
            padding: 16px;
            color: #00529f;
            font-weight: 700;
            text-align: center;
            display: inline-block;
            cursor: pointer;
            width: 265px;
        }

        .become-btn2 {
            width: initial;
            font-size: 16px;
            margin-top: -10px;
            margin-bottom: 40px;
        }

        .newspaper {
            font-size: 32px;
            cursor: initial;

            &:hover {
                text-decoration: none;
            }
        }

        .newspaper-content {
            font-size: 16px;
            margin-bottom: 50px;
        }

        .problem-title {
            background-color: #00b9f2;
            display: block;
            color: #fff;
            font-weight: 700;
            padding: 8px;
            font-size: 24px;
            text-align: center;

            > .pro-act:hover {
                color: #6d6e71;
                cursor: pointer;
            }
        }

        .newspaper-content2 {
            margin-top: 20px;
            font-size: 16px;
        }

        .news-index-top {
            width: 80%;
            padding: 40px 0px;
        }

        .news-index-top2 {
            width: 90%;
        }

        .new-read-title {
            font-weight: bold;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .view-more-nine {
            background-color: #e1e1e1;
        }

        .img-hg100 {
            .ant-image {
                height: 100%;
            }
        }
    }

    .position-r {
        position: relative;
    }

    a {
        color: #000;
    }

    .report-detail {
        margin-top:80px!important;
        .analysis-tag {
            background-color: #00529f;
            font-size: 14px;
            font-weight: 700;
            color: #fff;
            display: inline-block;
            padding: 4px 6px;
            border-radius: 4px;
        }

        .title-top {
            background-color: #00529f;
            padding: 16px;
            font-weight: 700;
            color: #fff;
        }

        .relative-essay {
            border-top: 3px solid #00529f;
            margin-top: 40px;
            padding-top: 20px;
            font-size: 24px;
            color: #00529f;
            padding-bottom: 20px;
            font-weight: bold;
        }
    }

    .blue-text {
        color: #00529f;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .ft14 {
        font-size: 14px;
    }

    .ft16 {
        font-size: 16px;
    }

    .mr10 {
        margin-right: 10px;
    }

    .ml15 {
        margin-left: 15px;
    }

    .mb15 {
        margin-bottom: 15px;
    }

    .mr15 {
        margin-right: 15px;
    }

    .mt20 {
        margin-top: 20px;
    }

    .chance {
        .ant-modal-footer {
            border-top: 0px;
        }
    }

    .update-time {
        font-size: 12px;
        color: #00529f;
    }

    .ft18 {
        font-size: 18px;
    }

    .news-nav-two {
        .news-text-title, a {
            font-size: 24px;
            color: #313784;
            margin-left:10px;
        }
    }

    .pl20 {
        padding-left: 20px;
    }

.news-list {
    margin-top: 50px !important;

    .give-zan {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .dot-circle {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #CCCCCC;
        margin-top: 4px;
    }

    .give-box {
        background: #F8F8F8;
        padding: 10px;
    }

    .comment-kuaixun {
        margin-top: 20px;
    }

    .travel-daily {
    }

    .kuaixun-view-more {
        cursor: pointer;

        &:hover {
            color: #386297;
        }
    }

    .hotel-title {
        position: absolute;
        bottom: 0px;
        left: 50%;
        .translate(-50%,0%);
        width: 94%;
        padding: 10px 12px;
        background: linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 13%,rgba(0,0,0,0.9) 100%);
    }

    .touxiang-img {
        padding-bottom: 20px;
        border-bottom: 1px solid #ececec;
        margin-top: 20px;
    }

    h3 {
        font-size: 18px;
    }

    .travel-daily {
        color: #999;
    }

    .kuaixun-join-comment {
        display: inline-block;
        width: 230px;
        height: 40px;
        margin: 30px auto 0;
        font-size: 14px;
        line-height: 40px;
        text-align: center;
        color: #006699;
        border-radius: 4px;
        border: 1px solid #006699;

        &:hover {
            background: #006699;
            color: #fff;
        }
    }

    .entry {
        position: absolute;
        width: 50px;
        height: 22px;
        top: 20px;
        right: 20px;
        color: #999;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        border-radius: 2px;
        border: 1px solid #E5E5E5;

        &:hover {
            color: #006699;
            border: 1px solid #006699;
        }
    }

    .entry-b {
        position: relative;
        padding: 15px;
    }

    .news-paper {
        font-size: 16px;
    }

    &.report-detail {
        margin-top: 80px !important;
    }

    .data-two {
        font-size: 12px;
        color: #999999;
    }

    .arrow-top {
        height: 65px;
        width: 14px;
        background: #313784;
        color: #fff;
        position: relative;
        display: inline-block;
    }

    .news-p {
        margin-left: 20px;
    }

    .GK-line {
        display: inline-block;
        flex: 1;
        height: 1px;
        background: #ececec;
    }

    .entry-img {
        position: relative;
        color: #fff;
        margin: 10px;
        overflow: hidden;
        border-radius: 10px;
        height: 266px;
    }



    .entry-bt {
        margin-bottom: 15px;
        border-radius: 4px;
        margin-top: 10px;
        background-color: #F8F8F8;
        padding-bottom: 10px;
    }

    .list-content1 {
      
        margin-bottom: 15px;
        font-size: 13px;
        color: #777777;
    }

    .list-content2 {
       
        font-size: 13px;
        margin-bottom: 20px;
        color: #777777;
    }

    .new-list-item-top {
        margin-top: 30px;
    }

    .company-person {
        color: #565656;
        font-size: 20px;
        margin-left: 30px;
        margin-right: 30px;
    }

    .text-int {
        text-indent: 32px;
    }

    .shape2 {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 10px solid #313784;
        position: absolute;
        top: -10px;
        left: -5px;
    }

    .yuanjin {
        display: inline-block;
        margin-bottom: 10px;
        width: 102px;
        height: 46px;
        line-height: 46px;
        color: #fff;
        text-align: center;
        border-radius: 10px;
        background: #313784;
    }

    .yuanjin-title {
        font-size: 18px;
        margin-bottom: 10px;
        width: 338px;
    }

    .yuanjin-box {
        position: absolute;
        top: -157px;
        left: 0px;
    }

    .newl-box {
        position: relative;
        margin-top: 230px;
    }

    .yuanjin-box2 {
        top: -6px;
        left: -328px;
    }

    .arrow-top2 {
        height: 15px;
        width: 100px;
    }

    .shape3 {
        top: -5px;
        left: -22px;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 10px solid #313784;
    }

    .shape4 {
        top: -5px;
        left: initial;
        right: -22px;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 10px solid #313784;
    }

    .big-biaoti {
        font-size: 24px;
        color: #252525;
        width: 95%;
        margin-bottom: 5px;
        .textOverflow(2);
        line-height: 1.3;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .yuanjin-shape-box3 {
        top: 75px;
        left: 230px;
        position: absolute;
    }

    .yuanjin-shape-box4 {
        top: 52px;
        left: 9px;
        position: absolute;
    }

    .small-biaoti {
        font-size: 14px;
        color: #666;
        .textOverflow(3);
    }

    .yuanjin-box3 {
        left: initial;
        right: -328px;
        top: 18px;
    }

    .time-icon {
        color: #aaa;
        font-size: 14px;
    }

    .time-before {
        color: #aaaaaa;
        font-size: 14px;
    }

    .tag-one {
        color: #386297;
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid #ececec;
    }

    .news-list-item-box {
        line-height: 2;
        margin-left: 8px;
    }

    .one-i {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        color: #fff;
        background: #cccccc;
        text-align: center;
        margin-right: 5px;
    }

    .sort-item {
        margin-bottom: 20px;
    }

    @media (max-width: 400px) {
        .GK-line {
            width: 18%;
        }
    }



    .one-active {
        background: #c4482b;
    }

    .two-active {
        background: #dca434;
    }

    .three-active {
        background: #586ce5;
    }

    .article-i-two {
        padding-bottom: 30px;
        border-bottom: 1px solid #ececec;
    }

    .con-tag {
        color: #666;
        background: #f5f5f5;
        margin-top: 20px;
        margin-bottom: 20px;
        display: inline-block;
        margin-right: 20px;
        padding: 2px 14px;
        border-radius: 4px;
    }

    .sort-box {
        background: #f8f8f8;
        width: 100%;
        padding-top: 18px;
        padding-bottom: 2px;
        padding-left: 15px;
    }
}

    .gray6 {
        color: #666;
    }

.newl {
    width: 320px;
    border: 2px solid #313784;
    border-radius: 60%;
    position: relative;
    margin: 0 auto;
}


.index-w80 {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 30px;

    .kuaixun-line-box {
        padding: 20px;
        padding-top: 2px;
        height: 1050px;
        overflow: hidden;
        position: relative;
    }

    .kuaixun-inner-box {
        height: 2000px;
        position: relative;
    }

    .kuaixun-top {
        position: absolute;
        animation: move 50s 3s linear infinite;

        &:hover {
            animation-play-state: paused;
        }
    }



    .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
        display: none;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
        display: none;
    }

    .sm-block {
        display: none;
    }

    @media (max-width: 768px) {
        img {
            width: 100%;
        }
    }

    .slick-slider .slick-track {
        width: 100% !important;
    }

    .article-box {
        width: 100%;
        height: 146px;
        position: relative;
        margin-bottom: 20px;
        border-radius: 6px;
        overflow: hidden;
    }

    .swiper-slide {
        border-radius: 6px;
        overflow: hidden;
    }

    .news-simple-title {
        font-size: 18px;
        font-weight: 400;
        color: #333;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .news-simple {
        color: #333333;
        font-size: 20px;
        margin-bottom: 24px;
        margin-top: 24px;
    }

    .article-title {
        position: absolute;
        bottom: 0;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 14px;
        width: 100%;
    }

    .weixin-icon {
        display: inline-block;
        background-color: #004988;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        border-radius: 50%;
    }

    .icon-box {
        margin-top: 20px;
        font-size: 20px;
    }

    .shouji-icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 35px;
        text-align: center;
        color: #fff;
        border-radius: 50%;
        background-color: #437DFF;
        margin: 0 3px;
        cursor: pointer;
    }

    .icon-active {
        background-color: #08BA06;
        color: #fff;
    }

    .qrcode {
        border: 1px solid #e9e9e9;
        padding: 10px 10px 10px;
        margin-bottom: 16px;
    }

    .article-img-left {
        position: relative;
    }

    .article-img-k {
        width: 94%;
        text-align: center;
        border-radius: 2px;
        /* height:160px;*/
        line-height: 160px;
    }

    .news-tag {
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        color: #fff;
        padding: 6px 12px;
    }

    .transport-title {
        font-size: 18px;
        color: #333;
        font-weight: bold;
        cursor: pointer;
    }

    .article-abs {
        font-size: 14px;
        color: #666;
        margin-top: 10px;
        text-align: justify;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        max-width:95%;
        width: 95%;
    }

    .article-ft {
        position: absolute;
        left: 0;
        right: 0px;
        bottom: 20px;
    }

    .article-ft-item {
        font-size: 12px;
        color: #999;
        margin-right: 25px;
    }

    .link-to {
        cursor: pointer;
    }

    .article-time {
        position: relative;
        padding-left: 30px;
    }

    .article-time:after {
        content: "";
        position: absolute;
        width: 4px;
        height: 4px;
        background-color: #999;
        left: 0;
        top: 50%;
        margin-top: -3px;
        border-radius: 50%;
    }

    .article-content {
        margin-bottom: 40px;
    }

    .link-to {
        color: #2E357D;
        font-size: 13px;
    }

    .shijian-icon {
        font-size: 20px;
        margin-right: 2px;
        display: inline-block;
        vertical-align: middle;
    }

    .time-icon {
        display: inline-block;
        vertical-align: middle;
        font-size: 13px;
    }

    .article-i {
        padding-top: 6px;
    }

    .article-abst {
        color: #888;
        font-size: 13px;
    }

    .readMore {
        display: inline-block;
        font-size: 17px;
        border-radius: 10px;
        color: #3F4688;
        border: 1px solid #3F4688;
        height: 58px;
        cursor: pointer;
        line-height: 58px;
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
    }



    .home-kx-title {
        font-size: 22px;
        color: #333;
        padding-left: 15px;
        background-size: 8px 20px;
    }

    .home-kx-b {
        background: #313784;
        color: #fff;
        padding: 4px;
        border-radius: 4px;

        .home-kx-title {
            color: #fff;
        }
    }

    .home-kx-item {
        position: relative;
        margin-bottom: 20px;
        padding-left: 15px;
    }

    .home-kx-item:before {
        content: "";
        position: absolute;
        top: 10px;
        bottom: -26px;
        width: 2px;
        background-color: #F3F3F3;
        left: -20px;
    }

    .icon-yuan {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        left: -23px;
        top: 10px;
        z-index: 999;
    }

    .icon-min {
        color: #999;
        font-size: 12px;
        margin-top: 10px;
    }

    .home-kx-time {
        font-size: 12px;
        color: #666;
        line-height: 20px;
    }



    .home-kx-desc {
        display: inline-block;
        padding-left: 12px;
        font-size: 14px;
        color: #333;
        text-align: justify;
        word-break: break-all;
        cursor: pointer;

        &:hover {
            color: #f44848;
        }
    }

    .home-kx-item:after {
        content: "";
        position: absolute;
        left: -27px;
        top: 6px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #9AA1FC;
    }

    .home-kx-i {
        margin-top: 20px;
    }

    .hot-article-title {
        height: 38px;
        font-size: 14px;
        color: #333;
        .textOverflow(2);
    }

    .hot-mt20 {
        margin-top: 20px;
    }

    .shape {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 10px solid #333;
        position: absolute;
        top: 30px;
        left: 50%;
        margin-left: -12px;
    }

    .travel-newspaper {
        font-size: 16px;
        color: #333;
        text-align: center;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 6px;
        margin-bottom: 30px;
        font-weight: bold;
        position: relative;
    }

    .hot-mb20 {
        margin-bottom: 25px;
    }

    .slick-prev:before {
        color: #999;
        font-size: 36px;
    }

    .slick-next:before {
        color: #999;
        font-size: 36px;
    }

    .slick-slider {
        width: 95%;
    }

    .slick-dots {
        bottom: 18px;
    }

    .slick-dots li.slick-active button:before {
        color: blue;
    }

    .slick-prev {
        left: 16px;
        z-index: 9999;
    }

    .slick-next {
        right: 32px;
    }

    @media (max-width: 998px) {
        .sm-block {
            display: block;
            margin-bottom: 15px;
        }

        .qrcode {
            margin-top: 20px;
        }

        .article-ft {
            position: initial;
        }

        .hot-article-title {
            margin-top: 10px;
        }
    }
}

.kuaixun-box {
    .media-wrap{
        overflow:hidden;
    }
    .blue-dot-circle {
        display: inline-block;
        width: 18px;
        height: 18px;
        background: #FAFAFA;
        border: 1px solid #3F73E8;
        border-radius: 50%;
        margin-top: 4px;
        position: relative;
        left: -10px;
        top: 0px;
    }

    .kuaixun-line {
        border-left: 1px dotted #ececec;
        padding-bottom: 20px;
    }

    .before-time {
        font-size: 12px;
        margin-top: 6px;
        color: #999;
    }

    .kuaixun-top {
        margin-top: 20px;
        padding: 10px;
    }

    .kuaixun-line-box {
        background: #f8f8f8;
        padding: 20px;
        padding-top: 2px;
        height: 400px;
        overflow: hidden;
        position: relative;
    }

    .kuaixun-inner-box {
        height: 1000px;
        position: relative;
    }

    .kuaixun-con {
        font-size: 16px;
    }

    .kuaixun-top {
        position: absolute;
        animation: move 50s 3s linear infinite;

        &:hover {
            animation-play-state: paused;
        }
    }

    .day-num {
        font-size: 50px;
        color: #006699;
    }

    .kuaixun-img-pic {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 20px;
        display: inline-block;
    }

    .news-day-box {
        text-align: right;
    }

    .kuaixun-view-more {
        font-size: 14px;
        line-height: 20px;
        color: #666666;
    }

    .kuaixun-icon-right {
        display: inline-block;
        color: #999;
    }


    .day-box2 {
        padding-right: 30px;
        margin-right: 30px;
        border-right: 1px solid #ececec;
    }

    .big-biaoti2 {
        margin-bottom: 10px;

        &:hover {
            color: #006699;
            text-decoration: none;
        }
    }

    .detail-content {
        img {
            width: 100%;
        }

        
    }

    .enjoy-text {
        margin-left: 32px;
        margin-right: 20px;
    }

    .kuaixun-weixin {
        font-size: 20px;
        cursor: pointer;

        &:hover {
            color: forestgreen;
        }
    }

    .kuaixun-weibo {
        font-size: 20px;
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;

        &:hover {
            color: red;
        }
    }

    .kuaixun-qq {
        font-size: 20px;
        cursor: pointer;

        &:hover {
            color: blue;
        }
    }

    .small-biaoti2 {
        color: #666;
    }

    .news-item-box2 {
        line-height: 2;
        margin-left: 20px;
        padding-bottom: 50px;
        border-bottom: 1px solid #ececec;
        margin-bottom: 20px;
    }

    .day-list {
        background: #ececec;
        color: #000;
        font-weight: bold;
        padding: 6px;
        text-align: center;
    }

    .h4-mb {
        margin-bottom: 0px;
    }

    .small-biaoti3 {
        font-size: 12px;
        margin-top: 10px;
    }

    .list-kuaixun {
        border-bottom: 1px solid #ececec;
        padding-top: 10px;
        padding-bottom: 30px;
    }

    .text-hidden {
        color: #666;
        .textOverflow(3);
    }

    .ml-link {
        margin-left: -15px;
        margin-top: 40px;

        .ant-btn-link {
            color: #386297;
        }
    }

    .mobile-kuaixun {
        margin-left: -28px;
        margin-right: -28px;
    }

    .icon-fenxiang {
        font-size: 18px;
    }

    @keyframes move {
        0% {
            top: 0px;
        }

        100% {
            top: -585px;
            /* top: -585px; */
        }
    }

    @media (min-width: 998px) {
        .mobile-kuaixun {
            display: none;
        }
    }
}

.coparation-box {
    margin-right: 30px;


    .company-img {
        margin-right: 20px;
        min-height: 152px;
        border: 1px solid #e5e5e5;
        line-height: 152px;
        border-radius: 6px;

        &:hover {
            box-shadow: 0px 0px 12px 4px rgba(0,0,0,0.08);
        }
    }

    .sort {
        padding-bottom: 2px;
        font-size: 14px;
        border-bottom: 2px solid transparent;
        color: #666666;
        cursor: pointer;
    }

    .sortLine {
        margin: 0 12px;
        color: #666666;
    }

    .sort-active {
        font-weight: bold;
        border-bottom-color: #fabb28;
        color: #252525;
    }

    .complete {
        background-image: linear-gradient(to right, #07c699, #02b389);
        color: #fff;
        padding: 8px 16px;
        border-radius: 80px;
        font-size: 14px;
        display: inline-block;
        cursor: pointer;
    }

    .companyItem {
        box-sizing: border-box;
        margin-bottom: 20px;
        padding: 24px 12px;
        border-radius: 12px;
        background-color: #ffffff;
        border: 1px solid #ececec;
        padding-top: 15px;
        padding-bottom: 8px;
        margin-bottom: 20px;

        &:hover {
            box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.08);
        }
    }

    .icon-complete {
        font-size: 18px;
        margin-right: 6px;
    }

    .companyName {
        display: block;
        margin-bottom: 12px;
        font-size: 24px;
        color: #252525;
        cursor: pointer;

        :hover {
            color: #fabb28;
        }
    }

    .companyLogo {
        padding: 6px;
        border-radius: 8px;
    }

    .coparation-content {
        font-size: 14px;
        text-overflow: ellipsis;
        display: -webkit-box; 
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical; 
        color: #999999;
        margin-bottom: 10px;
    }

    @media (max-width: 800px) {
        .coparation-content {
            width: 200px;
        }
    }

    .coparation-tag {
        width: 94px;
        display: inline-block;
        height: 30px;
        line-height: 30px;
        background: #EEEFF5;
        color: #35357D;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 19px;
        text-align: center;
    }
}
@media (max-width: 500px) {
   
            .coparation-box {
                width: 100%;
            }

            .news-index .news-index-top {
                width: 100%;
            }

            .xs-mt10 {
                margin-top: 10px;
            }
        }
        @media (max-width: 800px) {
            .mobile-con {
                width: 100% !important;
            }

            .index-w80 .article-img-k {
                width: 100%;
            }
        }

        @media (min-width: 1024px) {
            .index-w80 {
                width: 1040px;
                padding: 10px;
            }

            .report-detail {
                width: 76%;
                margin: 0 auto;
            }

            .coparation-box .coparation-content {
                width: 655px;
            }
        }

        .training-box {
            .ant-table-tbody > tr:nth-of-type(2n) {
                background: #ececec;
            }

            .ant-table-thead > tr > th {
                color: #000;
                font-weight: bold;
                color: #313784;
                font-size: 16px;
            }

            .ant-table-thead > tr > th {
                background: #ececec;
            }

            .table-title {
                font-size: 14px;
            }
        }


        .wenjuan-box {
            width: 60%;
            background: #fff;
            padding: 20px;

            .qiye-title {
                font-size: 24px;
                text-align: center;
                margin-bottom: 20px;
            }

            .wenjuan-pl56 {
                padding-left: 56px;
            }

            .qiye-text {
                text-align: center;
                margin-top: 8px;
                font-size: 16px;
            }

            .red {
                color: #f44848;
                font-size: 20px;
            }

            .wenjuan-item {
                font-weight: 600;
                font-size: 16px;
            }

            .money-item {
                padding: 10px 16px;
                font-size: 14px;
                color: #1f2329;
                background: #f5f5f5;
                border-radius: 4px;
            }

            .service-item {
                padding-bottom: 10px;
            }

            .wenjuan-footer {
                padding: 30px;
            }

            .wenjuan-box {
                padding: 10px;
                background: #fff;
                position: fixed;
                right: 14%;
                top: 30%;
                width: 50px;
                z-index: 999;
                box-shadow: 1px 2px 2px #ececec;
            }

            .wenjuan-line {
                position: relative;
                width: 8px;
                height: 170px;
                border-radius: 6px;
                background: #ececec;
                margin-left: 9px;

                > span {
                    position: absolute;
                    width: 8px;
                    border-radius: 6px;
                    background: #1890ff;
                }
            }
        }

        .wenjuan-b + .ant-row {
            display: none;
        }

        .wenjuan-b-card {
            background: #eee;


            .vip-card {
                font-size: 40px;
                color: #009614;
                margin-bottom: 10px;
            }

            .wenjuan-m {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            .red {
                color: #f44848;
                font-size: 16px;
            }

            .ft17 {
                font-size: 17px;
            }

            .wenjuansuccess-img {
                width: 40%;
                margin: 0 auto;
            }

            .shibie {
                display: none;
            }
        }

        @media (max-width: 500px) {
            .news-box .youjian-big-box .youjian-box {
                width: 100%;
            }

            .new-Index.preface-index .news-img-top {
                width: 100%;
                height: 560px;
            }

            .new-Index .preface-right-box {
                top: 384px;
            }

            .new-Index .preface-bg {
                height: 1634px;
            }

            .preface-index .register-box.register-box2 {
                bottom: 70px;
                width: 95%;
            }

            .preface-index .register-box.register-box2 .login-box2 {
                width: 100%;
            }

            .new-Index.person-index {
                .pc-w80 {
                    width: 100%;
                }
            }

            body {
                min-height: 100%;
            }

            .wenjuan-b-card {
                min-height: 100%;
            }

            #root {
                min-height: 100%;
            }

            .news-box {
                min-height: 100%;
            }

            .wenjuan-b-card {
                .shibie {
                    display: block;
                }

                .wenjuansuccess-img {
                    width: 100%;
                }

                .wenjuan-m {
                    padding-top: 0px;
                    padding-bottom: 0px;
                }
            }

            .wenjuan-box {
                width: 100%;

                .wenjuan-box {
                    position: fixed;
                    right: 0%;
                    top: 60%;
                    width: 50px;
                    z-index: 999;
                    box-shadow: 1px 2px 2px #ececec;
                }

                .wenjuan-pl56 {
                    padding-left: 10px;
                }
            }
        }

        .pd15 {
            padding: 15px;
        }

        .new-wenjuan2 {
            .new-wenjuan2-title {
                font-size: 18px;
            }
        }

        .preface-index {
            .register-shop {
                width: 180px;
                text-align: center;
                padding: 10px;
                color: #fff;
                background-color: #d9aa52;
                margin-right: 10px;
                font-size: 16px;
                font-weight: bold;
            }

            .register-box {
                position: absolute;
                bottom: 20px;
                right: 0px;
            }

            .register-box2 {
                bottom: -20px;
            }

            .preface-language {
                width: 20px;
                color: #071963;
                font-size: 28px;
                font-weight: bold;
            }

            .login-title {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .login-box2 {
                padding: 30px;
                background: #fff;
                margin-right: 20px;
                width: 336px;
            }

            .login-input {
                border: 0px;
                padding-left: 0px;
            }

            .login-phone-i {
                border-bottom: 1px solid #ececec;
                margin-bottom: 20px;
            }

            .login-btn {
                background: #061862;
                border-color: #061862;
                height: 40px;
                line-height: 30px;
            }

            .login-weixin {
                color: #061862;
                font-size: 40px;
            }

            .gray9 {
                color: #999;
            }

            .login-nochoose {
                background: #999;
                border-color: #999;
            }

            .news-img-top {
                width: 68%;
                height: 400px;
                position: relative
            }

            .blue {
                color: #02679a;
            }

            .youxiang-icon {
                font-size: 22px;
                color: #313784;
            }
        }

        .icon-error {
            color: #f44848;
            font-size: 28px;
            margin-right: 10px;
        }

        .person-index {
            .pc-w80 {
                width: 80%;
                margin: 0 auto;
            }

            .person-left {
                padding: 20px;
                background: #fff;
                margin-right: 20px;
            }

            .person-left-item {
                padding: 10px;
                color: #313784;
                text-align: center;
                cursor: pointer;
            }

            .person-right {
                padding: 20px;
                background: #fff;
            }

            .person-left-item-active {
                background: #A2B8F7;
            }

            .person-shouji {
                font-size: 22px;
                color: #454545;
                font-weight: bold;
            }

            .go {
                font-size: 16px;
                color: #0066B3;
                cursor: pointer;
            }

            background: #ececec;
            margin-top: 0px !important;
            padding-top: 80px;

            .red {
                color: #f44848;
            }
        }

        .down-menu2 {
            width: 120px;
            text-align: center;
        }

        .shouzhi {
            cursor: pointer;
        }

        .preface-bg {
            background: url('../src/images/G&Kxiangqing.jpg');
            background-size: 100% 100%;
            position: relative;
            height: 400px;
        }

        .preface-right-box {
            width: 48%;
            right: 8%;
            position: absolute;
            top: 98px;
        }

        .mb20 {
            margin-bottom: 20px;
        }

        .time-modal {
            color: #454545;
            font-weight: bold;
        }

        .orange-text {
            color: #D16A5B;
        }

       

        .youjian-big-box {
          
            /*.pdf-canvas{
                display:flex;
                justify-content:center;
                width:100%;
            }*/

            .youjian-text-box {
                padding: 15px;
            }

            .youjian-box {
                width: 56%;
                position: relative;

                .once-baoming {
                    /*background-color: #db9c1b;*/
                    color: #fff;
                    width: 30%;
                    font-weight: bold;
                    font-size: 32px;
                    position: absolute;
                    bottom: 15%;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            .once-detail-btn {
                background: #010099;
                font-size: 18px;
                color: #fff;
                font-weight: bold;
                padding: 6px 20px;
                cursor:pointer;
            }

            .once-detail-btn2 {
                background-color: #db9c1b;
            }

            .pb20 {
                padding-bottom: 40px;
            }

            .youjian-b {
                background-color: #010099;
                padding: 30px;
            }

            .fb {
                font-weight: bold;
            }

            .youjian-about-text {
                color: #fff;
            }

            .local-lan {
                color: #091a76;
            }

            .click-btn {
                color: #8b6b56;
                margin-left: 5px;
                margin-right: 5px;
                cursor: pointer;
            }
        }
.youjian2-big-box {
    .huoban {
        font-size: 24px;
        color: #181818;
        font-weight: bold;
        margin-left: 5px;
    }

    .flex-start {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .huoban-text {
        font-size: 14px;
        color: #727272;
        margin-top: 10px;
    }

    .youjian-text-box {
        padding: 25px 0px;
    }

    .quanyi-item {
        color: #F7DFB2;
        font-size: 15px;
        background: url("../src/images/youjian2/email2-black-bg.png");
        background-size: 100% 100%;
        width: 206px;
        height: 116px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .quanyi-item2 {
        color: #6C4615;
        background: url("../src/images/youjian2/email2-ora-bg.png");
        background-size: 100% 100%;
    }

    .quanyi-item3 {
        color: #005D99;
        background: url("../src/images/youjian2/email2-lan-bg.png");
        background-size: 100% 100%;
    }

    .quanyi-item4 {
        color: #181818;
        background: url("../src/images/youjian2/email2-white-bg.png");
        background-size: 100% 100%;
    }

    .flex-wrap {
        display: flex;
        flex-wrap: wrap;
    }

    .quanyi-yellow {
        color: #DB9C1B;
        font-size: 15px;
        font-weight: bold;
        padding-left: 10px;
    }

    .once-baoming2 {
        width: 24%;
        font-weight: bold;
        font-size: 32px;
        position: absolute;
        right: 4%;
        top: 50%;
        transform: translateY(-50%);
    }

    .yuanzhuo-type {
        color: #727272;
        font-size: 15px;
        margin-top: 5px;
    }

    .yuanzhuo-yellow {
        color: #DB9C1B;
    }

    .yuanzhuo-theme {
        color: #181818;
        font-size: 15px;
        font-weight: bold;
        margin-top: 10px;
    }

    .email2-lan-box {
        color: #004CDE;
        font-weight: bold;
        font-size: 15px;
        background: #E5EDFC;
        padding: 10px;
        margin-top: 10px;
    }

    .email2-ml5 {
        margin-left: 5px;
    }

    .email2-yellow2 {
        color: #DB9C1B;
        font-size: 15px;
        font-weight: bold;
    }

    .email2-yellow2-text {
        color: #727272;
    }

    .email2-top20 {
        margin-top: 26px;
    }

    .email2-pd10 {
        padding: 15px;
        padding-bottom: 20px;
        padding-top: 5px;
    }

    .email2-lan-outer-box {
        background: #FFFFFF;
        box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.1);
    }

    .youjian-code2 {
        color: #181818;
        font-size: 20px;
        font-weight: bold;
        margin-left: 10px;
        width: 68%;
    }

    .email2-code-bottom {
        background: url("../src/images/youjian2/email2-code-bg.png");
        background-size: 100% 100%;
        padding:20px;
    }
}


@media (max-width:500px) and (min-width:100px) {
    .youjian-big-box .youjian-box.pdf-box {
        width: 100%;
    }
    .youjian-big-box{
        width:100%;
    }
    .youjian2-big-box .quanyi-item {
        width: 50%;
    }
    .youjian2-big-box .youjian-text-box {
        padding: 25px 10px;
    }
    .pdf-controlls-container {
        position: fixed;
        bottom: 0px;
        width: 92%;
        height: 40px;
        background: #fff;
        text-align: center;
        /* margin-bottom: -2px; */
        z-index: 999999999;
        left: 50%;
        transform: translateX(-50%);

        .view-control {
            display: inline-block;
            width: 33.33%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border: 1px solid #ececec;
            font-size: 14px;
            font-style: normal;
        }

        .zoom-in:before {
            content: "+";
            font-style: normal;
            font-size: 14px;
        }

        .zoom-reset:before {
            content: "100%";
            font-style: normal;
            font-size: 14px;
        }

        .zoom-out:before {
            content: "-";
            font-style: normal;
            font-size: 14px;
        }
    }
}
@primary-color: #313784;